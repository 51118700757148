<template>
  <ion-fab v-if="whappyClientConfig.name == 'Yoube'" vertical="bottom" horizontal="end">
    <ion-fab-button @click="$router.push('/shopping-cart')" class="ion-margin-end ion-margin-bottom">
      <ion-icon :icon="cart"></ion-icon>
    </ion-fab-button>
  </ion-fab>
  <ion-fab v-if="whappyClientConfig.name == 'Trivellato' ||
    whappyClientConfig.name == 'Automotive SpA'
    " vertical="bottom" horizontal="end">
    <ion-fab-button @click="openModalNewKpiChat" class="ion-margin-end ion-margin-bottom" color="light">
      <ion-icon :icon="add"></ion-icon>
    </ion-fab-button>
  </ion-fab>





  <campaign-list-layout :showInfoPopHover="true" :showProfilePic="this.whappyClientConfig.showAvatarCampaigns"
    :infoPopHover="whappyClientConfig.name == 'Yoube'
      ? INFOPOPOVER_MAP[whappyClientConfig.name]
      : INFOPOPOVER_MAP['default']
      " smallTitle="true" bigTitle="Campagne" :showBack="false" titleStyle="vertical" :noPadding="true">
    <div class="page">
      <div class="w-full h-full flex flex-column">

        <div @click="toggleCollapse" class="w-full flex justify-content-between align-items-center section-title">
          <h4 class="font-bold">Le tue attività
            ({{ items.length }})
          </h4>
          <div class="horizontal-bar"></div>

        </div>
        <!-- Collapsible Content -->
        <div v-if="isCollapsed" style="width: 100%; display: flex; flex-direction: column;">
          <div v-for="(item) in items" :key="item.name"
            style="width: 100%; padding: 12px 16px; background: var(--ion-color-secondary); color: var(--ion-color-primary); border-radius: var(--ion-border-radius); margin-bottom: 1rem; ">
            <div class="w-full flex justify-content-start align-items-center" style="min-height: 40px;">
              <span>{{ item.name }}</span>
            </div>
            <div class="w-full flex justify-content-end align-items-center">
              <button @click="openDetails(item)"
                style="background: transparent; border: none; color: var(--ion-color-primary); font-size: 0.8rem; cursor: pointer; padding: 4px 8px; border-radius: 4px; transition: background 0.3s;"
                onmouseover="this.style.background='rgba(255,255,255,0.1)'"
                onmouseout="this.style.background='transparent'">
                Dettagli
              </button>
            </div>
          </div>
        </div>


        <div class="w-full flex justify-content-between align-items-center section-title">
          <h4 class="font-bold">Le tue campagne</h4>
          <div class="horizontal-bar"></div>
        </div>
        <div :style="{
          'max-height':
            whappyClientConfig.name == 'Demo' ||
              whappyClientConfig.name == 'Automotive SpA'
              ? '40vh'
              : '100vh',
          'overflow-y': 'scroll',
        }">
          <template v-if="loadingCampaigns">
            <p class="greeting-tag">Caricamento in corso...</p>
          </template>

          <template v-else-if="displayList !== null && displayList.length === 0">
            <p class="greeting-tag">
              {{ $t("campaigns.no_campaigns") }}
            </p>
          </template>
          <transition-group name="block-slide-in">
            <template v-for="(listItem, i) in displayList" :key="i">
              <trCard :style="{ '--i': i, 'max-height': '150px', height: '150px' }"
                :img="listItem.url_mission_cover && listItem.show_cover_in_menu" v-if="showList1 && showList2"
                @click="$router.push(getCampaignDefaultUrl(listItem))" cardType="secondary" sideWidth="lg" class="mb-4"
                :percCompletamento="true">
                <template #perc-completamento>
                  {{ Number(listItem.percentualeCompletamento).toFixed(0) }}%
                </template>
                <template #main>
                  <div class="w-full flex flex-column py-2">
                    <div class="flex flex-column campaign-name-and-details my-2">
                      <span class="text-14 font-bold">
                        {{ listItem.title }}
                      </span>
                      <span class="text-14">
                        {{ trimString(listItem.description, 43) }}
                      </span>
                    </div>
                    <div class="w-full h-full progressbar py-2 my-2">
                      <div class="campaign-datetime text-color-gray mb-2">
                        <span class="text-10">{{
                          new Date(listItem.data_inizio).toLocaleDateString(
                            "it",
                            {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )
                        }}</span>
                        -
                        <span class="text-10">{{
                          new Date(listItem.data_fine).toLocaleDateString(
                            "it",
                            {
                              year: "2-digit",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )
                        }}</span>
                      </div>
                      <dark-common-progress-bar :value="(listItem.totalNumberOfDays -
                            listItem.remainingNumberOfDays) /
                          listItem.totalNumberOfDays
                          "></dark-common-progress-bar>
                      <span v-if="listItem.remainingNumberOfDays > 0" class="text-10">{{ listItem.remainingDaysString
                      }}</span>
                      <span class="text-10" v-else>La campagna è terminata</span>
                    </div>
                  </div>
                </template>
                <template #right-side>
                  <div class="campaign-img">
                    <img v-if="listItem.url_mission_cover &&
                      listItem.show_cover_in_menu
                      " :src="listItem.url_mission_cover" @error="handleImgError" />
                  </div>
                </template>
              </trCard>
            </template>
          </transition-group>
        </div>
        <div v-if="whappyClientConfig.name == 'Demo' ||
          whappyClientConfig.name == 'Automotive SpA'"
          class="w-full flex justify-content-between align-items-center section-title">
          <h4 class="font-bold">News feed</h4>
          <div class="horizontal-bar"></div>
        </div>
        <div v-if="whappyClientConfig.name == 'Demo' ||
          whappyClientConfig.name == 'Automotive SpA'" style="max-height: 30vh; overflow-y: scroll">
          <div class="horizontal-scroll">
            <div class="horizontal-box-container">
              <template v-for="(item, index) in horizontalScrollItems" :key="index">
                <NewsCard :item="item" class="news-card" @update-reactions="updateReactions($event, item)" />
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </campaign-list-layout>
</template>

<script>
import ApiService from "../common/service.api";
import trCard from "@/components/Nuovi/trCard.vue";
import NewsCard from "@/components/Nuovi/NewsCard.vue";

import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonItem,
  IonRow,
  IonCol,
  modalController,
  IonFabButton,
  IonFab,
  IonLabel,
  IonList,
  IonPage
} from "@ionic/vue";
import CampaignService from "../common/mixins/Campaign";
import UserService from "../common/mixins/User";
import { IonProgressBar } from "@ionic/vue";

import {
  stopwatchOutline,
  eyeOutline,
  cart,
  add,
  cartOutline,
  list,
} from "ionicons/icons";
import CampaignListLayout from "../components/CampaignListLayout.vue";
import DarkCommonProgressBar from "../components/DarkCommonProgressBar";
import ModalNewOrdine from "./Modals/ModalNewOrdine.vue";

import { useGeneralStore } from "../stores/useGeneralStore";

const generalStore = useGeneralStore();

export default {
  name: "Campaigns",
  components: {
    IonLabel,
    CampaignListLayout,
    ModalNewOrdine,
    IonItem,
    IonCard,
    IonCardContent,
    IonIcon,
    IonProgressBar,
    DarkCommonProgressBar,
    IonRow,
    IonCol,
    trCard,
    NewsCard,
    IonFabButton,
    IonFab,
    IonList,
    IonPage
  },
  mixins: [CampaignService, UserService],
  created() {
    // this.getGenUsers();
    this.loadingCampaigns = true;
    console.log("get campaigns ... ");
    var data = this.getCampaigns({
      filterForHome: true,
      forceRefresh: true,
    })
      .then((data) => {
        this.displayList.splice(0);
        if (this.settings.skip_menu_campagne && data.length) {
          this.$router.push(this.getCampaignDefaultUrl(data[0]));
        }
        data.forEach((element) => {
          element.view_supervisor = this.isSupervisedCampaign(element);
          this.displayList.push(element);
          setTimeout(() => {
            this.$nextTick(() => {
              this.showList1 = true;
            });
          }, 1);
        });
        // this.displayList = data;
      })
      .finally(() => {
        this.loadingCampaigns = false;
      });
  },
  mounted() {
    //     this.displayList.forEach((element) => {
    //       element.view_supervisor = this.isSupervisedCampaign(element);
    //     });
    setTimeout(() => {
      this.$nextTick(() => {
        this.showList2 = true;
      });
    }, 1);
  },
  data() {
    return {
      isCollapsed: false,
      items: [
        { name: 'Completamento del percorso di OnBoarding' },
        { name: 'Raggiungimento del 40% nel Badge "Top Vendite"' },
        { name: 'Meet con il tuo Supervisore' },
        { name: 'Inizio della campagna "Agosto 2024"' },
      ],
      horizontalScrollItems: [
        {
          description: "Matt Damon",
          title: "Ha vinto il badge: Competenza Comunicazione",
          image:
            "https://api-automotive-demo.whappy.it/Drive/0a7e616a-4ed7-4a9d-a975-4a5f996a6aac_image.jpg",
          userPic:
            "https://api-automotive-demo.whappy.it/Drive/e9c15714-5370-4d6c-879f-ac090a568588_50_mejores_actores_vivos_gq_731461606.webp",
          reactions: {
            "👍": 10,
            "❤️": 2,
            "😆": 0,
            "😢": 0,
            "😮": 4,
          },
        },

        {
          description: "Tom Hanks",
          title: "Ha vinto il badge: Impossible Wall 15%",
          image:
            "https://api-automotive-demo.whappy.it/Drive/ab6dd696-aacb-4056-be33-f2c3d593dbca_image.jpg",
          userPic:
            "https://api-automotive-demo.whappy.it/Drive/2d86856f-2755-4590-8a9f-3f550eaa1bb8_image%20(2).png",
          reactions: { "👍": 0, "❤️": 0, "😆": 0, "😢": 0, "😮": 3 },
        },

        {
          description: "Gene Hackman",
          title: "Ha vinto il badge: Hai completato le task!",
          image:
            "https://api-automotive-demo.whappy.it/Drive/63586bd3-b8f1-4042-a698-0d5fde2a5c27_image.jpg",
          userPic:
            "https://api-automotive-demo.whappy.it/Drive/d3abf141-62d4-4f7c-a633-a9e2f1425abe_image%20(3).png",
          reactions: { "👍": 2, "❤️": 0, "😆": 0, "😢": 0, "😮": 3 },
        },
        {
          description: "Al Pacino",
          title: "Ha vinto il badge: Hai raggiunto un obiettivo di vendita!",
          image:
            "https://api-automotive-demo.whappy.it/Drive/1c520aa3-b429-436e-82ee-1107cfd8c4ec_image.jpg",
          userPic:
            "https://api-automotive-demo.whappy.it/Drive/f68f1d85-f1ad-4919-b796-2551df399a3d_actors_22100_642225.jpeg",
          reactions: { "👍": 1, "❤️": 0, "😆": 0, "😢": 0, "😮": 0 },
        },
      ],
      loadingCampaigns: false,
      cart,
      add,
      cartOutline,
      stopwatchOutline,
      userAvatar: null,
      displayList: [],
      isSupervised: false,
      eyeOutline,
      showList1: false,
      showList2: false,
      INFOPOPOVER_MAP: {
        Yoube:
          "In questa sezione trovi le campagne extra promozionali a te dedicate. Piccole azioni ti faranno accumulare riconoscimenti e crediti. Potrai tenere monitorate le tue statistiche e cosa ti manca per ottenere riconoscimenti e premi.",
        default:
          "In questa sezione trovi le campagne extra promozionali a te dedicate. Piccole azioni ti faranno accumulare riconoscimenti e crediti. Potrai tenere monitorate le tue statistiche e cosa ti manca per ottenere riconoscimenti e premi.",
      },
      options: [],
    };
  },
  methods: {
    toggleCollapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    getGenUsers() {
      generalStore.getUsers();
    },
    trimString(str, maxLength) {
      if (str.length > maxLength) {
        return str.substring(0, maxLength - 3) + "...";
      }
      return str;
    },
    updateReactions(event, item) {
      console.log("UPDATE REACTIONS", { event, item });
      item.reactions = event;
    },
    handleImgError() {
      return "https://placehold.co/300x300?text=MartechRevolution";
    },
    async openModalNewKpiChat() {
      const modal = await modalController.create({
        component: ModalNewOrdine,
        cssClass: "common-modal",
      });

      modal.onDidDismiss().then(async () => {
        await ApiService.get(`SalesChat/GetOrders`).then((res) => {
          this.items = [];
          res.data.forEach((element) => {
            this.items.push(element);
          });
        });
      });
      return modal.present();
    },
  },
};
</script>

<style lang="scss" scoped>
ion-card {
  --background: var(--ion-color-primary);

  &.closed {
    --background: var(--ion-color-primary-shade);
  }

  color: var(--text-color-light);

  ion-icon {
    font-size: 150%;
    margin: 0 5px -3px 0;
  }
}

.status {
  width: 70%;
  float: left;
  font-size: 70%;
}

.percentage {
  width: 30%;
  float: left;
  text-align: right;
  font-weight: bold;
}

.details {
  width: 100%;
  clear: both;

  h3 {
    font-size: 110%;
    color: var(--text-color-light);
    font-weight: bold;
    margin-bottom: 0px;
  }

  .period {
    font-size: 70%;
  }
}

.page {
  background: white;
  padding: 1rem;
  min-height: calc(100vh - 220px);
}

.campaign-img {
  height: 100%;
  max-height: 222px !important;

  img {
    height: 100%;
    object-fit: cover;
  }
}

.section-title {
  color: var(--ion-color-primary);
}

.horizontal-bar {
  max-width: 50%;
  width: 100%;
  height: 3px;
  background-color: var(--ion-color-primary);

  margin-top: 10px;
}

.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  padding: 10px;
}

.horizontal-box-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}

.news-card {
  scroll-snap-align: start;
}
</style>
